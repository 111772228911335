@import url('https://fonts.googleapis.com/css2?family=Forum:wght@400&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.tabcont {
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(0.9);
}
div#tab1 {
  margin-top: 5vh;
}
.tabs {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.tab {
  width: 190px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: #f1f1f1;
}
.tab.active {
  background-color: #ccc;
}
.tab-content {
  flex-wrap: wrap;
  display: flex;
  padding-bottom: 7vmin;
  border-radius: 5px;
  align-items: center;
  max-width: 1200px;
  justify-content: space-around;
}
.tab-content.active {
  display: flex;
}
h2.h2 {
  text-align: center;
}
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vmin;
  height: 90vmin;
  position: relative;
}
.circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.25vmin solid #000;
}
.center-image {
  position: absolute;
  top: 48%;
  left: 51%;
  width: 70%;
  height: 70%;
  transform: translate(-50%, -50%);
}
/* Outer circle points */
.outer-point {
  position: absolute;
  background-color: black;
  border-radius: 50%;
  transition: transform 0.2s;
}
.tab-in {
  position: relative;
  display: flex;
  margin:0 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tab-in .star-point {
  font-weight: 500;
  border: none;
  background-color: transparent;
  font-size: 1.9em;
}
.outer-point.small {
  width: 0.55vmin;
  height: 0.55vmin;
margin-left: -1px;
  margin-top: -1px;
}
.hidden {
  display: none;
}
.outer-point.large {
  width: 1vmin;
  height: 1vmin;
}
.outer-point:hover {
  transform: scale(1.1); /* Increase size by 10% */
}
.aaa {
  width: 36vmin!important;
  margin-top: 14vmin;
}
/* Labels */
.label {
  position: absolute;
  font-size: 1vmin;
  font-weight: bold;
  font-family: 'Inter';
  color: #000;
  transform: translate(-50%, -50%);
  white-space: nowrap; /* Prevent text wrapping */
}
.label.large {
  font-size: 1.5vmin;
  font-weight: bold;
  font-family: 'Inter';
}
/* Numbers inside the circle */
.number {
  position: absolute;
  color: #000;
  transform: translate(-50%, -50%);
  white-space: nowrap; /* Prevent text wrapping */
}
.number.large {
  font-size: 1.5vmin;
  font-weight: bold;
}
.number.small {
  font-size: 1vmin;
}
/* Star points */
.star-point {
  position: absolute;
  width: 6vmin;
  height: 6vmin;
  font-family: 'Inter';
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  color: rgba(132, 0, 22, 1);
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #E5E5E5;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s forwards; /* Fade in animation */
}
#circle .star-point {
  outline: 0.6vmin solid rgba(229, 229, 229, 0.5);
}
@keyframes fadeIn {
  to {
      opacity: 1;
  }
}
.input-container button {
  font-size: 2.4vmin!important;
  margin-top: 33px!important;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
#star-point1 {
  animation-delay: 1.2s;
}
#star-point2 {
  animation-delay: 2.2s;
}
#star-point3 {
  animation-delay: 1.4s;
}
#star-point4 {
  animation-delay: 2.6s;
}
#star-point5 {
  animation-delay: 1.8s;
}
#star-point12 {
  animation-delay: 3s;
}
#star-point13 {
  animation-delay: 3.2s;
}
#star-point14 {
  animation-delay: 3.4s;
}
#star-point15 {
  animation-delay: 3.8s;
}
#star-point16 {
  animation-delay: 3.6s;
}
#star-point6 {
  animation-delay: 4s;
}
#star-point7 {
  animation-delay: 4.2s;
}
#star-point8 {
  animation-delay: 4.4s;
}
#star-point9 {
  animation-delay: 4.6s;
}
#star-point10 {
  animation-delay: 4.8s;
}
#star-point11 {
  animation-delay: 5s;
}
.outer-point.large {
  color: #850117;
  background-color: #850117;
}
.large {
  color: #850117;
}

#circle .star-point5 {
  background: #C611DB;
  outline: 0.9vmin solid rgba(198, 17, 219, 0.5);
  color: white;
  width: 7vmin;
  font-size: 3.1vmin;
  height: 7vmin;
}
#circle .star-point2 {
  background: rgba(220, 2, 39, 1);
  outline: 0.9vmin solid rgba(220, 2, 39, 0.5) !important;
  color: white;
  width: 7vmin;
  font-size: 3.1vmin;
  height: 7vmin;
}
#circle .star-point3 {
  background: rgb(132, 0, 22);
  background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
  outline: 0.9vmin solid rgba(132, 0, 22, 0.5) !important;
  color: white;
}
#circle .star-point4 {
  background: rgb(132, 0, 22);
  background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
  outline: 0.9vmin solid rgba(132, 0, 22, 0.5) !important;
  color: white;
}
#circle .star-point1 {
  background: rgb(132, 0, 22);
  background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
  outline: 0.9vmin solid rgba(132, 0, 22, 0.5);
  color: white;
}
#circle .star-point7 {
  background: #0B57EA;
  outline: 0.9vmin solid rgba(11, 87, 234, 0.5);
  color: white;
}
#circle .star-point8 {
  background: rgba(11, 170, 234, 1);
  outline: 0.9vmin solid rgba(11, 170, 234, 0.5);
  color: white;
}
#circle .star-point9 {
  background: rgba(9, 207, 41, 1);
  outline: 0.9vmin solid rgba(9, 207, 41, 0.5);
  color: white;
  z-index: 2;
}
#circle .star-point10 {
  background: rgba(238, 231, 21, 1);
  outline: 0.9vmin solid rgba(238, 231, 21, 0.5);
  color: white;
  z-index: 2;
}
#circle .star-point11 {
  background: rgba(241, 123, 36, 1);
  outline: 0.9vmin solid rgba(241, 123, 36, 0.5);
  color: white;
  z-index: 2;
}
#text-container {
  text-align: center;
  margin: 0 auto;
}
#text-container ul {
  text-align: left;
}
@media screen and (max-width: 500px) {
#text-container {

padding: 0 20px;
}
.card{width: 80vmin!important;}
.input-container input{    width: 80%!important; 
 padding: 1px;}
.tabcont {
 min-height: 100%;
}
#circle .star-point {
 font-size: 2.5vmin;
}
.tab-in .star-point {
 font-size: 1.6em;
}
.aaa {
 width: 44vmin !important;
 margin-top: 16vmin;
 margin-bottom: 5vmin;
}
.tab-content{flex-direction: column;}
}