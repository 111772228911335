@import url('https://fonts.googleapis.com/css2?family=Forum:wght@400&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


.tab-content {
  flex-wrap: wrap;
  display: flex;
  padding-bottom: 7vmin;
  border-radius: 5px;
  align-items: center;
  max-width: 1200px;
  justify-content: space-around;
}
h2.h2 {
  text-align: center;
}
/* Outer circle points */
.outer-point {
  position: absolute;
  background-color: black;
  border-radius: 50%;
  transition: transform 0.2s;
}
.tab-in {
  position: relative;
  display: flex;
  margin:0 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tab-in .star-point {
  font-weight: 500;
  border: none;
  background-color: transparent;
  font-size: 1.9em;
}
.outer-point.small {
  width: 0.55vmin;
  height: 0.55vmin;
margin-left: -1px;
  margin-top: -1px;
}
.hidden {
  display: none;
}
.outer-point.large {
  width: 1vmin;
  height: 1vmin;
}
.outer-point:hover {
  transform: scale(1.1); /* Increase size by 10% */
}
.aaa {
  width: 36vmin!important;
  margin-top: 14vmin;
}
/* Labels */
.label {
  position: absolute;
  font-size: 1vmin;
  font-weight: bold;
  font-family: 'Inter';
  color: #000;
  transform: translate(-50%, -50%);
  white-space: nowrap; /* Prevent text wrapping */
}
.label.large {
  font-size: 1.5vmin;
  font-weight: bold;
  font-family: 'Inter';
}
/* Numbers inside the circle */
.number {
  position: absolute;
  color: #000;
  transform: translate(-50%, -50%);
  white-space: nowrap; /* Prevent text wrapping */
}
.number.large {
  font-size: 1.5vmin;
  font-weight: bold;
}
.number.small {
  font-size: 1vmin;
}
/* Star points */
.star-point {
  position: absolute;
  width: 6vmin;
  height: 6vmin;
  font-family: 'Inter';
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  color: rgba(132, 0, 22, 1);
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #E5E5E5;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s forwards; /* Fade in animation */
}
#circle .star-point {
  outline: 0.6vmin solid rgba(229, 229, 229, 0.5);
}
@keyframes fadeIn {
  to {
      opacity: 1;
  }
}
.input-container button {
  font-size: 2.4vmin!important;
  margin-top: 33px!important;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
#star-point1 {
  animation-delay: 1.2s;
}
#star-point2 {
  animation-delay: 2.2s;
}
#star-point3 {
  animation-delay: 1.4s;
}
#star-point4 {
  animation-delay: 2.6s;
}
#star-point5 {
  animation-delay: 1.8s;
}
#star-point12 {
  animation-delay: 3s;
}
#star-point13 {
  animation-delay: 3.2s;
}
#star-point14 {
  animation-delay: 3.4s;
}
#star-point15 {
  animation-delay: 3.8s;
}
#star-point16 {
  animation-delay: 3.6s;
}
#star-point6 {
  animation-delay: 4s;
}
#star-point7 {
  animation-delay: 4.2s;
}
#star-point8 {
  animation-delay: 4.4s;
}
#star-point9 {
  animation-delay: 4.6s;
}
#star-point10 {
  animation-delay: 4.8s;
}
#star-point11 {
  animation-delay: 5s;
}
.outer-point.large {
  color: #850117;
  background-color: #850117;
}
.large {
  color: #850117;
}
#tab2 {
  margin-top: 6vmin;
}
#tab3 .star-point21 {
  background: rgb(132, 0, 22);
  width: 3vmin;
  font-size: 1.6vmin;
  height: 3vmin;
  background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
  outline: 0.5vmin solid rgba(132, 0, 22, 0.5);
  color: white;
}
#circle .star-point5 {
  background: #C611DB;
  outline: 0.9vmin solid rgba(198, 17, 219, 0.5);
  color: white;
  width: 7vmin;
  font-size: 3.1vmin;
  height: 7vmin;
}
#circle .star-point1 {
  background: rgb(132, 0, 22);
  background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
  outline: 0.9vmin solid rgba(132, 0, 22, 0.5);
  color: white;
}
#circle .star-point7 {
  background: #0B57EA;
  outline: 0.9vmin solid rgba(11, 87, 234, 0.5);
  color: white;
}
#tab3 .star-point7 {
  background: #0B57EA;
  width: 4vmin;
  height: 4vmin;
  font-size: 2vmin;
  outline: 0.6vmin solid rgba(11, 87, 234, 0.5);
  color: white;
}
#tab3 .star-point8 {
  width: 4vmin;
  height: 4vmin;
  background: rgba(11, 170, 234, 1);
  font-size: 2vmin;
  outline: 0.6vmin solid rgba(11, 170, 234, 0.5);
  color: white;
}
#tab3 .star-point9 {
  width: 4vmin;
  height: 4vmin;
  background: rgba(9, 207, 41, 1);
  font-size: 2vmin;
  outline: 0.6vmin solid rgba(9, 207, 41, 0.5);
  color: white;
  z-index: 2;
}
#tab3 .star-point10 {
  width: 4vmin;
  height: 4vmin;
  background: rgba(238, 231, 21, 1);
  font-size: 2vmin;
  outline: 0.6vmin solid rgba(238, 231, 21, 0.5);
  color: white;
  z-index: 2;
}
#tab3 .star-point11 {
  width: 4vmin;
  height: 4vmin;
  background: rgba(241, 123, 36, 1);
  font-size: 2vmin;
  outline: 0.6vmin solid rgba(241, 123, 36, 0.5);
  color: white;
  z-index: 2;
}
#circle .star-point8 {
  background: rgba(11, 170, 234, 1);
  outline: 0.9vmin solid rgba(11, 170, 234, 0.5);
  color: white;
}
#circle .star-point9 {
  background: rgba(9, 207, 41, 1);
  outline: 0.9vmin solid rgba(9, 207, 41, 0.5);
  color: white;
  z-index: 2;
}
#circle .star-point10 {
  background: rgba(238, 231, 21, 1);
  outline: 0.9vmin solid rgba(238, 231, 21, 0.5);
  color: white;
  z-index: 2;
}
#circle .star-point11 {
  background: rgba(241, 123, 36, 1);
  outline: 0.9vmin solid rgba(241, 123, 36, 0.5);
  color: white;
  z-index: 2;
}
#text-container {
  text-align: center;
  margin: 0 auto;
}
#text-container ul {
  text-align: left;
}

@media screen and (max-width: 500px) {
  .star-point32{   left: 34% !important;font-size: 1.2em!important; top: 47.2%!important;}
  .star-point36{left: 36% !important; font-size: 1.2em!important; top: 47.2%!important;}
.star-point31,.star-point29,.star-point33,.star-point35,.star-point37{    top: 47.2%!important;
font-size: 1.2em!important;}
.star-point28,.star-point34,.star-point30{  left:38%!important;  top: 47.2%!important;
font-size: 1.2em!important;}
.st211,.st19{left: -1.4%!important;}
.st22,.st26{left: 10.4%!important;}
.st17{left: 17.4%!important;}
.st8{left: 3.6%!important;}
.st10{left: 84%!important;}
.st20{left: 5.6%!important;}
.st21{left: 84%!important;}
.st199{left: 86%!important;}



#text-container {

padding: 0 20px;
}
.card{width: 80vmin!important;}
.input-container input{    width: 80%!important; 
 padding: 1px;}
#tab3 .star-point7 {
 background: #0B57EA;
 width: 9vmin;
 height: 9vmin;
 font-size: 4vmin;
 outline: 0.6vmin solid rgba(11, 87, 234, 0.5);
 top: 47%!important;
 color: white;
 left: 16% !important;
}
#tab3 .star-point8 {
 background: rgba(11, 170, 234, 1);
 width: 9vmin;
 height: 9vmin;
 font-size: 4vmin;
 outline: 0.6vmin solid rgba(11, 170, 234, 0.5);
 top: 47%!important;
 color: white;
 left: 29.7% !important;
}
#tab3 .star-point9 {
 width: 9vmin;
 height: 9vmin;
 background: rgba(9, 207, 41, 1);
 font-size: 4vmin;
 outline: 0.6vmin solid rgba(9, 207, 41, 0.5);
 top: 47%!important;
 color: white;
 z-index: 2;

}
#tab3 .star-point10 {
 width: 9vmin;
 height: 9vmin;
 background: rgba(238, 231, 21, 1);
 font-size: 4vmin;
 outline: 0.6vmin solid rgba(238, 231, 21, 0.5);
 top: 47%!important;
 color: white;
 z-index: 2;
}
#tab3 .star-point11 {
 width: 9vmin;
 height: 9vmin;
 background: rgba(241, 123, 36, 1);
 font-size: 4vmin;
 outline: 0.6vmin solid rgba(241, 123, 36, 0.5);
 top: 47%!important;
 color: white;
 z-index: 2;
}
#tab3 .star-point5 {
        left: 2vmin !important;
 width: 9vmin!important;
 height: 9vmin!important;
 top: 48%!important;
 font-size: 4vmin!important;
}
#tab3 .star-point2 {
 left: 89%!important;
 width: 9vmin!important;
 height: 9vmin!important;
 top: 48%!important;
 font-size: 4vmin!important;
}
#tab3 .star-point-new1 {
 width: 9vmin !important;
 font-size: 3.9vmin !important;
 top: 60%!important;
 height: 9vmin !important;
}
#tab3 .star-point-new2 {
 width: 9vmin !important;
 font-size: 3.9vmin !important;
 top: 60%!important;
 height: 9vmin !important;
}
#tab3 .star-point-new3 {
 width: 9vmin !important;
 font-size: 3.9vmin !important;
 top: 60%!important;
 left: 52.8%!important;
 height: 9vmin !important;
}

.tabcont {
 min-height: 100%;
}
#circle .star-point {
 font-size: 2.5vmin;
}
.tab-in .star-point {
 font-size: 1.6em;
}
.aaa {
 width: 44vmin !important;
 margin-top: 16vmin;
 margin-bottom: 5vmin;
}
#tab2 .star-point5,
.tab-in .star-point1,
.tab-in .star-point2,
.tab-in .star-point3,
.tab-in .star-point4 {
 left: 43.7% !important;
}
#tab2 .star-point9,
.tab-in .star-point14,
.tab-in .star-point16,
.tab-in .star-point15,
.tab-in .star-point4 {
 left: 44.4% !important;
}
#tab2 .star-point7,
.tab-in .star-point17,
.tab-in .star-point23 {
  left: 74% !important;
}
#tab2 .star-point28 {
  left: 36% !important;
}
#tab2 .star-point19 {
  left: -10% !important;
}
#tab2 .star-point11 {
  left: 16% !important;
}
#tab2 .star-point101 {
  left: 92% !important;
}
#tab2 .star-point10 {
  left: 3% !important;
}
#tab2 .star-point20 {
  left: 83% !important;
}
#tab2 .star-point32 {
  left: 38% !important;
}
#tab2 .star-point194{
  left: 92% !important;
}
#tab2 .star-point21 {
  left: -5% !important;
}
#tab2 .star-point266 {
  left: 16% !important;
}
#tab2 .star-point200 {
  left: 4% !important;
}
#tab2 .star-point211 .st21 {
  left: 90% !important;
}
#tab3 .aaa {
 width: 90%!important;
}
#tab3 .star-point21 {
 background: rgb(132, 0, 22);
 width: 9vmin!important;
 font-size: 3.9vmin!important;
 height: 9vmin!important;
 background: linear-gradient(130deg, rgba(132, 0, 22, 1) 0%, rgba(132, 0, 22, 1) 100%);
 outline: 1vmin solid rgba(132, 0, 22, 0.5)!important;
 top: 83.5%!important;
 color: white;
}
.tab-content{flex-direction: column;}
}

/* Energy Display Styles */

.energy-container {
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: white;
  color: black;
  padding: 20px;
}

.energy-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.energy-section {
  margin-bottom: 20px;
}

.energy-section-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.energy-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 20px;
}

